import { useNavigate, useSearch } from '@tanstack/react-router'
import { memo, useEffect } from 'react'
import { GlobalLoadingState } from '../molecules/global-loading-state'

const ActivitySearchRestaurantPage = memo(function ActivitySearchRestaurantPage() {
  const navigate = useNavigate()
  const searchParams = useSearch({ strict: false })
  const discountCodeInUrl = searchParams.discount_code
  useEffect(() => {
    void navigate({
      to: '/subscribe/landing',
      search: {
        category: 'restaurantsCoffee',
        discount_code: discountCodeInUrl ? discountCodeInUrl : '',
      },
    })
  }, [discountCodeInUrl, navigate])
  return <GlobalLoadingState />
})

export default ActivitySearchRestaurantPage
